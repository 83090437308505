<template>
  <div class='border-t flex flex-col py-12' style='background-color: #373535; color: #FFF;'>
    <div
      class='layout-container w-full flex flex-col lg:flex-row gap-y-8 lg:gap-x-16 justify-between px-4 lg:px-0 text-xs lg:text-sm'>
      <div class='w-full lg:w-1/2 flex-grow-0 flex-shrink-0'>
        <h3 class='font-semibold text-sm lg:text-base'>KHRS 2022 Secretariat</h3>
        <h4>INTERCOM Convention Services, Inc.</h4>
        <div class='mt-4 text-sm flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Telephone</div>
          <div>+82-2-564-4367</div>
        </div>
        <div class='mt-2 text-sm flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Email</div>
          <div>khrs-reg@intercom.co.kr</div>
        </div>
        <div class='mt-2 text-sm flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Address</div>
          <div>4-7F, squareME Building, 225 Bongeunsa-ro, Gangnam-gu, Seoul 06109</div>
        </div>
      </div>
      <div class='w-full lg:w-1/2 flex-grow-0 flex-shrink-0'>
        <div class='font-semibold text-sm lg:text-base'>Korean Heart Rhythm Society</div>
        <div class='mt-2 text-sm flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Email</div>
          <div>khrs@k-hrs.org</div>
        </div>
        <div class='mt-2 text-sm flex flex-row justify-start'>
          <div class='uppercase inline-block opacity-70 w-36 flex-shrink-0'>Address</div>
          <div>1604, Block A, 372, Hangang-daero, Yongsan-gu, Seoul 04323</div>
        </div>
      </div>
    </div>
    <div class='layout-container w-full row-centered justify-start lg:gap-x-16 mt-8'>
      <div class='w-1/2 flex-grow-0 flex-shrink-0'>
        <img
          src='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/simpl_footer_image.png'
          class='h-12' />
      </div>
      <div class='w-1/2 flex-grow-0 flex-shrink-0'>
        <a href='https://d3r2ol85dktaw0.cloudfront.net/conferences/khrs-virtual/KHRS+2022_Privacy+Policy_0614.pdf'
          class='inline-block uppercase bg-white text-gray-600 font-semibold py-2 px-4 rounded-md'
          target='_blank'>Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KhrsVirtualLayoutFooter',
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  }
}
</script>
